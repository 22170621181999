import Head from "next/head";
import * as React from "react";
import Nav from "../components/Nav";
import Footer from "../components/Footer";
import { Button, Heading } from "../components/Shared";

const Custom404 = () => {
  return (
    <>
      <Head>
        <title>Page Not Found | Greener House</title>
      </Head>
      <div className="h-100 d-flex flex-column">
        <Nav logoLink="/" theme="dark" />
        <section className="d-flex flex-grow-1 bg-blue align-items-center">
          <div className="container d-flex flex-column flex-grow-1 align-items-center pt-5 pb-5">
            <div className="container-sm-fixed text-center">
              <Heading
                title="Oops, we couldn't find that."
                isBold={true}
                variant={2}
              />
              <div className="mb-5"></div>
              <Button
                name="Go Back"
                type="button"
                title="Go Back"
                onClick={() => {
                  history.back();
                }}
              />
            </div>
          </div>
        </section>
        <Footer />
      </div>
    </>
  );
};

export default Custom404;
